import { client as apiClient } from '@/services/api'
import Vue from 'vue'
import router from '@/router'

export async function initGoogleSignInScript () {
  return new Promise((resolve) => {
    if (document.getElementById('google-signin-script')) {
      resolve(window.google)
      return
    }

    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.id = 'google-signin-script'
    script.async = true
    script.defer = true
    script.onload = () => resolve(window.google)
    document.body.appendChild(script)
  })
}

export async function loadGapiScript () {
  return new Promise((resolve) => {
    if (document.getElementById('gapi-script')) {
      resolve(window.gapi)
      return
    }
    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.async = true
    script.defer = true
    script.id = 'gapi-script'
    script.onload = () => {
      window.gapi.load('client', async () => {
        await window.gapi.client.load(
          'https://sheets.googleapis.com/$discovery/rest?version=v4'
        )
        await window.gapi.client.load(
          'https://content.googleapis.com/discovery/v1/apis/drive/v3/rest'
        )
        resolve(window.gapi)
      })
    }
    document.body.appendChild(script)
  })
}

async function requestAuthCode (email, scope) {
  const google = await initGoogleSignInScript()

  return new Promise((resolve, reject) => {
    const googleClient = google.accounts.oauth2.initCodeClient({
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      scope,
      ux_mode: 'popup',
      hint: email,
      callback: async (response) => {
        if (response.error) {
          reject(response.error)
          return
        }
        resolve(response)
      },
      select_account: false,
      error_callback: (e) => {
        reject(e)
      }
    })
    googleClient.requestCode()
  })
}

export async function checkTokenScope (scope = 'https://www.googleapis.com/auth/drive.file') {
  try {
    await apiClient.get(`/google/check-scopes`)
    // todo: check if scope is in response
    return true
  } catch (_) {
    return false
  }
}

export async function ensureGoogleScope (scope = 'https://www.googleapis.com/auth/drive.file') {
  const hasScope = await checkTokenScope(scope)
  return hasScope
}

export async function getGoogleDrivePermissions (email, scope = 'https://www.googleapis.com/auth/drive.file') {
  try {
    const { code } = await requestAuthCode(email, scope)
    console.log('auth code', code)
    const redirectUri = window.location.origin
    await apiClient.post(`/google/oauth2-code`, null, { params: { code, redirect_uri: redirectUri } })
    return true
  } catch (e) {
    console.log(e)
    console.log('error', e)
    if (e !== 'access_denied' && e.type !== 'popup_closed') {
      // logout for users with legacy auth
      await Vue.$auth.logout()
      router.push({ name: 'login' })
    }
    return false
  }
}
