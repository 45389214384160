<template>
  <div class="page-ai-creator">
    <div class="page-ai-creator__left">
      <div class="page-ai-creator__left-container">
        <h1 class="page-ai-creator__title">✨ AI Creator</h1>
        <div class="page-ai-creator__form-wrap">
          <!-- FORM -->
          <form v-if="activeStep !== 3" class="ai-creator-form" @submit.prevent="submitPrompt">
            <p class="ai-creator-form__hint">Describe your site or select one of the prompts below and click on <b>Generate</b></p>
            <textarea
              v-model="prompt"
              class="ai-creator-form__textarea"
              placeholder="Start here..."
              :disabled="isLoading"
              >
            </textarea>
            <div class="ai-creator-form__tags">
              <div
                v-for="(example, i) in examplePrompts"
                :key="i"
                class="ai-creator-form__tag"
                :class="{
                  'is-disabled': isLoading
                }"
                @click="updatePrompt(example)"
              >
                {{ example.title }}
              </div>
            </div>
            <div class="ai-creator-form__btns">
              <UiBtn
                :label="isLoading ? 'Generating...' : 'GENERATE ✨'"
                type="primary"
                attrType="submit"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </div>
          </form>
          <!-- FORM -->
          <div
            v-else
            class="ai-creator-form"
          >
            <p class="ai-creator-form__hint">Watch the results and continue editing in SpreadSimple App</p>
            <p class="ai-creator-form__prompt"> <b>Prompt:</b> {{ prompt }}</p>
            <div class="ai-creator-form__btns">
              <UiBtn
                label="Continue to editor"
                type="green"
                appendImg="/img/icon-logo.svg"
                :loading="isLoading"
                :disabled="isLoading"
                @click="checkGoogleDrivePermissions"
              />
              <UiBtn
                label="Try another prompt"
                type="ghost"
                attrType="button"
                prependIcon="im-edit-containered"
                @click="tryAnotherPrompt"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-ai-creator__right">
      <div v-if="activeStep === 1" class="page-ai-creator__hero">
        <img src="/img/ai-creator-main-3.png" class="page-ai-creator__hero-img">
      </div>
      <!-- STATUSES THEATER -->
      <div v-else-if="activeStep === 2" class="page-ai-creator__flow-wrap">
        <div class="ai-creator-flow">

          <transition-group
            tag="div"
            name="ai-creator-msgs"
            class="ai-creator-flow__msgs"
          >
            <div
              v-for="(item, i) in visibleReplicas"
              class="ai-creator-flow__msg"
              :key="i"
            >
              <div class="ai-creator-flow__msg-icon">✨</div>
              <div class="ai-creator-flow__msg-body">
                <h5 class="ai-creator-flow__msg-title">
                  <span class="text--color-primary">{{ item.title }} </span>
                </h5>
                <p v-if="item.options.content" class="ai-creator-flow__msg-string">{{ item.options.content }}</p>
                <div v-if="item.options.img" class="ai-creator-flow__msg-img" :style="`background-image: url(${item.options.img});`"></div>
                <div v-if="item.options.logo" class="ai-creator-flow__msg-img" style="width: 60px;">
                  <img :src="item.options.logo" alt="logo">
                </div>
                <div v-if="item.options.color" class="ai-creator-flow__msg-color">
                  <div class="ai-creator-flow__msg-color-preview" :style="`background-color: ${item.options.color};`"></div>
                  <p class="ai-creator-flow__msg-color-title">{{ item.options.color }}</p>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <!-- STATUSES THEATER -->
      <div v-else class="page-ai-creator__result-wrap">
        <div class="ai-creator-result">
          <div class="ai-creator-result__radio-wrap">
            <label
              v-for="(item, i) in resultPreviewTypes"
              :key="i"
              class="ai-creator-result__radio"
              :class="{'is-active': resultPreviewType === item.value}"
            >
              <input type="radio" v-model="resultPreviewType" :value="item.value">
              <span class="ai-creator-result__radio-label">{{ item.label }}</span>
              <UiIcon :icon="item.icon" class="ai-creator-result__radio-icon" />
            </label>
          </div>
          <div
            v-if="resultPreviewType === 'website'"
            class="ai-creator-result__website web-preview"
            :class="previewType === 'desktop' ? 'is-desktop' : 'is-mobile' "
          >
            <div class="web-preview__topbar">
              <div class="web-preview__topbar-decor">
                <div class="web-preview__topbar-decor-item"></div>
                <div class="web-preview__topbar-decor-item"></div>
                <div class="web-preview__topbar-decor-item"></div>
              </div>
              <div class="web-preview__topbar-field">
                <span class="web-preview__topbar-field-prepend">https://</span>
                <div class="web-preview__topbar-field-value is-hidden"></div>
              </div>
              <div class="web-preview__topbar-switcher-wrap">
                <label
                  v-for="item in previewTypes"
                  :key="item.value"
                  class="web-preview__topbar-switcher"
                  :class="{'is-active': previewType === item.value}"
                >
                  <input type="radio" v-model="previewType" :value="item.value" >
                  <i :class="item.icon"></i>
                </label>
              </div>
            </div>
            <div
              class="web-preview__body"
              :class="previewType === 'desktop' ? 'is-zoomed' : '' "
            >
              <AiCreatorPreview
                v-if="websitePreviewOptions"
                :previewInitOptions="websitePreviewOptions"
                :generationId="generationId"
              />
            </div>
          </div>
          <div v-else class="ai-creator-result__sheet-wrap">
            <div class="ai-creator-result__sheet-scroll-container">
              <table class="ai-creator-result__sheet">
                <thead>
                  <tr>
                    <th>
                      <div>
                        <div class="ai-creator-result__sheet-head-decor"></div>
                        <div class="ai-creator-result__sheet-head-cell is-decor">1</div>
                      </div>
                    </th>
                    <th v-for="(cell, i) in tableContent.header" :key="i">
                      <div>
                        <div class="ai-creator-result__sheet-head-decor">{{'abcdefghijklmnopqrstuvwxyz'.slice(i, i + 1).toUpperCase()}}</div>
                        <div class="ai-creator-result__sheet-head-cell">
                          <p>{{ cell }}</p>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, i) in tableContent.rows"
                    :key="i"
                  >
                    <td>{{ i+2 }}</td>
                    <td v-for="(cell, j) in row" :key="j">
                      {{ cell }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="openErrorModal"
      modalName="Generation Error"
      :hasCloseButton="true"
    >
      <div class="pb-32">
        <div class="text--align-center mt-16">
          {{ generationError }}
        </div>
        <UiBtn class="ml-auto mr-auto mt-24" wide @click="openErrorModal = false">
          Ok
        </UiBtn>
      </div>
    </Modal>
    <Modal v-model="googleDrivePermissionsModal" :hasCloseButton="false" max-width="700px" top="auto"
        style="--modalPaddingTop: 0;" @input="googleDrivePermissionsModal = false">
        <div class="ui-flex ui-flex--items-start gap-20 p-around-28">
          <img src="/img/lock-2.svg" alt="lock icon" class="ui-flex--none">
          <div>
            <h4 class="heading--size-s text--weight-700">Allow SpreadSimple to create and read the created Google Sheets
            </h4>
            <p class="mt-12 text--weight-500">The app will not be able to access or read any other files stored on your
              Google Drive. Your other data will remain safe.</p>
          </div>
          <div>
            <UiBtn label="Allow..." :disabled="isLoading" :loading="isLoading" uppercase full-width @click="createGoogleSheet(true)" />
            <UiBtn label="Dismiss" :disabled="isLoading" :loading="isLoading" uppercase full-width class="mt-12" type="secondary-text-blue"
              @click="googleDrivePermissionsModal = false" />
          </div>
        </div>
    </Modal>
    <Modal
      v-model="modalOverLimitSpreadViews"
      :modalName="'You reached your website quantity limit'"
      closeOnPressEscBtn
      class="confirmation-popup"
      alignTitleLeft
      @input="modalOverLimitSpreadViews = false"
    >
      <div class="confirmation-popup__content">
        <p>You can’t add more websites to your workspace.
          <a href="https://help.spreadsimple.com/en/article/website-quantity-limitation-iul9ct/" target="_blank" rel="nofollow" class="over-limit-modal__link">
              Learn more about the website quantity limit.
          </a>
        </p>
        <p>To create a new website, do one of the following steps: </p>
        <ul>
          <li>delete one of your existing websites</li>
          <li>update your subscription plan in the Billing Settings to increase the number of licenses</li>
        </ul>
      </div>
      <div class="confirmation-popup__btns">
        <UiBtn
          type="secondary"
          @click="modalOverLimitSpreadViews = false">OK</UiBtn>
        <UiBtn
          type="primary"
          @click="$router.push({ name: 'account-billing' })">UPDATE SUBSCRIPTION</UiBtn>
      </div>
    </Modal>
    <Preloader :loading="creatingWebsite" :content="contentMsg" showProgress />
  </div>
</template>

<script>
import UiBtn from '@/components/ui-btn'
import UiIcon from '@/components/ui-icon'
import AiCreatorPreview from '@/components/ai-creator-form/AiCreatorPreview.vue'
import Modal from '@/components/modal'
import defaultFieldsSchema from '@/components/form-builder/defaultFieldsSchema.js'
import api from '@/services/api/'
import aiApi from '@/services/api/ai-api'
import { defaultOptions } from 'spreadview-options'
import { createEncodedWriteOptions } from 'google-sheets-data'
import { mapActions, mapGetters, mapState } from 'vuex'
import { ensureGoogleScope, getGoogleDrivePermissions } from '@/modules/google-utils'

const preloaderMsgs = [
  '💡 A template doesn’t limit your website behavior — all features and add-ons are available for all websites',
  '💡 Optimize your images: smaller files take less time to load',
  '💡 Don’t forget about meta: add meta tags to all your pages for better SEO results',
  '💡 Submit your sitemap to Google Search Console to get your website indexed faster',
  '💡 Highlight certain items in the catalog using our .class trick — visit the help desk for the instructions',
  '💡 Custom scripts and custom CSS are welcome — you can inject them in the Settings tab',
  '💡 Need category pages? Create them using the URL aliases feature — visit the help desk for more details'
]

const defaultSteps = {
  'arguments': null,
  'coverImage': null,
  'logo': null,
  'rows': null,
  'rowsWithImages': null,
  'spreadsheet': null,
  'options': null,
  'result': null
}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'PageAiCreator',
  components: {
    UiBtn,
    UiIcon,
    AiCreatorPreview,
    Modal
  },
  data() {
    return {
      contentMsg: preloaderMsgs[0],
      activeMsgIndex: 1,
      creatingWebsite: false,
      previewTypes: [
        { value: 'desktop', icon: 'icon-preview-desktop' },
        { value: 'mobile', icon: 'icon-preview-mobile' }
      ],
      previewType: 'desktop',
      websitePreviewOptions: null,
      websitePreviewUrl: undefined,
      generationId: undefined,
      isLoading: false,
      ownerSheetUrl: undefined,
      openErrorModal: false,
      prompt: '',
      steps: null,
      generationError: undefined,
      activeStep: 1,
      resultPreviewTypes: [
        { value: 'website', label: 'Website Preview', icon: 'mi-visibility' },
        { value: 'sheet', label: 'Spreadsheet Preview', icon: 'mi-description' }
      ],
      resultPreviewType: 'website', // website, sheet
      replicasFlow: [],
      visibleReplicas: [],
      keepForTimer: null,
      examplePrompts: [
        { title: 'Affiliate Store', prompt: 'Create a website for a freelance services platform called “SkillBridge.” This platform connects experienced professionals with clients who are looking for their services in various industries, such as design, writing, marketing, and programming. Include features such as service categories, freelancer profiles with ratings, and secure payment options. Use images of people’s faces for cards, and use a blue and white color scheme to build trust. Do not use a shopping cart. The platform supports English and US dollars.' },
        { title: 'Templates Store', prompt: 'Create a professional website to sell ready-made house plans called “DreamHome Designs”. Showcase a variety of house plans sorted by size, style, and budget. Include detailed descriptions, 3D renderings, and a secure checkout process. Use a modern and minimalist design with a neutral color palette such as white, gray, and light blue to highlight the content. The website should support English language and US dollar transactions. Use pictures of cozy houses, villas, cottages depending on the context.' },
        { title: 'Job Board', prompt: 'Create a job posting website specifically for football referees in the UK. The site should display job listings in a list format showing the job title, experience required, expected salary, age range and location for each role. For the card images, use logos of football referees in action. Below the job title, include the name of the organisation hiring. For the introduction, use an image of a football referee. Use a professional and clean design with a green and white colour scheme reflecting the sport. Include a search bar, no side filters and no shopping cart. Do not use sorting. The language of the site should be English and all salaries should be in pounds sterling.' },
        { title: 'Tourist Guide', prompt: 'Create a guidebook highlighting the best museums in Paris. The website should list each museum with basic information including the museum name, location, admission price, opening hours, and a short description. The homepage should have a map showing the exact location of the museum. Use color images of the museum exterior or key exhibits only. For the introductory image, include an attractive shot of the Louvre or another iconic Parisian museum. The design should have a gray and green theme. Do not use a shopping cart, but rather have a button on the museum detail page that links to the museum. Make sure the language is English and the prices are in Euros.' },
        { title: 'Online Courses', prompt: 'Create a website called CraftMastery Online dedicated to online craft courses. The homepage should include categories such as Knitting, Embroidery, Pottery, and Paper Crafts, each represented by visually appealing icons. Use a soft pastel color scheme with beige, lavender, and lime green accents to create a creative and welcoming atmosphere. Include a Beginner Courses section and a Testimonials section with student reviews. Course description pages should include video previews, a list of required materials, and instructor biographies. Add badges with popular courses. The site should be in English, with prices in US dollars.' },
        { title: 'Grocery Store', prompt: 'Create a website for Golden Delight Eastern Sweets, a local Chicago shop specializing in Turkish delights. The homepage should feature categories such as baklava, Turkish delight, and kunefe. Use warm gold and deep red as the primary colors to create a cozy and luxurious atmosphere. Include a “Daily Specials” section to highlight new arrivals and discounts. Use a shopping cart. Make sure the site language is English and prices are displayed in US dollars.' },
        { title: 'Video Catalog', prompt: 'Create a website called Play It Right that serves as a video catalog for learning how to play various musical instruments. Each card should include the name of the instrument, a high-quality image, and a brief three-sentence description of the instrument and its unique features. Add a button that links to a relevant YouTube video tutorial for learning to play that instrument. Use a vibrant and artistic design with tones like deep blue and gold to evoke creativity and elegance. The site should be in English, with no sorting options or side filters.' }
      ],
      googleDrivePermissionsModal: false,
      modalOverLimitSpreadViews: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    ...mapGetters('app', [
      'licenseTypeToApplyOnCreate',
      'amountOfAvailableActiveSpreadViews'
    ]),
    expandedDefaultOptions() {
      const copyOfDefaults = JSON.parse(JSON.stringify(defaultOptions))
      copyOfDefaults.checkout.fields = defaultFieldsSchema.schema
      return copyOfDefaults
    },
    tableContent () {
      if (!this.steps.rowsWithImages) return {}
      return this.steps.rowsWithImages.reduce((acc, row) => {
        if (acc.header.length === 0) {
          acc.header = Object.keys(row)
        }
        acc.rows.push(Object.values(row))
        return acc
      }, { header: [], rows: [] })
    }
  },

  methods: {
    ...mapActions('app', [
      'fetchWorkspaces',
      'updateSpreadViewDeal'
    ]),
    async checkIfCanCreateNewView () {
      let views = []
      views = await api.getViewsList()
      const activeViewsLength = views.filter(view => !view.isArchived).length
      if (activeViewsLength >= this.amountOfAvailableActiveSpreadViews) {
        this.modalOverLimitSpreadViews = true
        this.isLoading = false
        return false
      }
      return true
    },
    updatePrompt (example) {
      this.$trackEvent({
        'event': 'clickOnPromptExample',
        'promptName': example.title
      })
      this.prompt = example.prompt
    },
    async submitPrompt () {
      this.$trackEvent({
        'event': 'clickOnAiGenerateButton'
      })
      this.isLoading = true
      this.generationError = undefined
      this.steps = null
      this.activeStep = 2
      let attempts = 0
      const maxAttempts = 100
      this.steps = Object.assign({}, defaultSteps)
      // console.time('generateWebsiteTask')

      this.addFlowItem('Analyzing your prompt...', { keepFor: 800 })
      this.addFlowItem('Generating essential website elements...', { keepFor: 800 })

      const onTaskUpdate = async (taskStatus, result) => {
        if (taskStatus === 'FAILED') {
          this.generationError = result || 'There was an error during the generation. Please try again later.'
          this.openErrorModal = true
          return console.error('Task failed', result)
        }

        if (result.stepName === 'arguments') {
          const argKeys = [
            ['brandName', 'Brand Name'],
            ['websiteHeading', 'Website Heading'],
            ['websiteSubheading', 'Website Subheading'],
            ['theme', 'Theme'],
            ['primaryColor', 'Primary Color'],
            ['websiteLocale', 'Website Locale'],
            ['homePageIntroText', 'Home Page Intro Text']
          ]
          for (const [key, label] of argKeys) {
            if (result.stepData[key]) {
              if (label === 'Primary Color') {
                this.addFlowItem(label, { color: result.stepData[key], keepFor: 800 })
              } else {
                this.addFlowItem(label, { content: `${result.stepData[key].length > 200 ? result.stepData[key].slice(0, 200) + '...' : result.stepData[key]}`, keepFor: 800 })
              }
            }
          }
        }
        if (result.stepName === 'logo') {
          this.addFlowItem('Generating logo...', { logo: this.steps.logo, keepFor: 1500 })
        }
        if (result.stepName === 'coverImage') {
          this.addFlowItem('Found a cover image...', { img: this.steps.coverImage, keepFor: 1500 })
        }
        if (result.stepName === 'rows') {
          this.addFlowItem('Generating website rows...', { keepFor: 800 })
        }
        if (result.stepName === 'rowsWithImages') {
          this.addFlowItem('Finding the relevant website images...', { keepFor: 800 })
        }
        if (result.stepName === 'spreadsheet') {
          this.addFlowItem('Creating a spreadsheet...', { keepFor: 800 })
          this.addFlowItem('Filling out the spreadsheet columns...', { keepFor: 800 })
          this.addFlowItem('Filling out the spreadsheet rows...', { keepFor: 800 })
          this.addFlowItem('Formatting the spreadsheet...', { keepFor: 800 })
        }
        if (result.stepName === 'result') {
          const { options } = result.stepData
          this.addFlowItem('Hurray!', { content: 'Generation is done',
            keepFor: 800,
            whenDone: () => {
              this.websitePreviewOptions = options
              this.onFlowComplete()
            } })

          // console.timeEnd('generateWebsiteTask')
        } else {
          // console.timeLog('generateWebsiteTask')
        }
      }

      const checkStatusUpdate = async (taskId) => {
        this.isLoading = true
        if (attempts >= maxAttempts) return onTaskUpdate('FAILED', 'Max attempts reached')
        attempts++
        let taskData = null
        try {
          const { data } = await aiApi.generationStatus(taskId)
          taskData = data
        } catch (e) {
          console.warn(e)
        }
        if (taskData.steps) {
          for (let stepName in this.steps) {
            if (this.steps[stepName]) continue
            if (taskData.steps[stepName]) {
              this.steps[stepName] = taskData.steps[stepName]
              onTaskUpdate(taskData.status, { stepName, stepData: taskData.steps[stepName] })
            }
          }
        }

        if (taskData.status === 'COMPLETED') {
          return // stop pooling
        }

        if (taskData.status === 'FAILED') {
          this.onFlowFailed()
          return onTaskUpdate('FAILED', taskData.steps.error.message)
        }

        await sleep(2000)
        checkStatusUpdate(taskId)
      }

      try {
        this.isLoading = true
        const { data } = await aiApi.generateWebsite({
          prompt: this.prompt,
          options: this.expandedDefaultOptions
        })
        this.$router.replace({ 'query': { generationId: data.generationId } })
        this.generationId = data.generationId
        await checkStatusUpdate(data.generationId)
      } catch (err) {
        onTaskUpdate('FAILED', err)
        this.onFlowFailed()
      }
    },

    addFlowItem (title, options = { content: null, img: null, logo: null, color: null, keepFor: 800 }) {
      this.replicasFlow.push({ title, options })
      if (this.replicasFlow.length >= 1 && !this.playing) {
        this.playNextReplica()
      }
    },

    playNextReplica () {
      this.clearKeepForTimer()
      if (this.replicasFlow.length > 0) {
        const replica = this.replicasFlow.shift()
        this.playing = true
        if (replica.options.keepFor > 0) {
          this.visibleReplicas.push(replica)
          this.keepForTimer = setTimeout(() => {
            if (replica.options.whenDone) replica.options.whenDone()
            this.playNextReplica()
          }, replica.options.keepFor)
        }
      } else {
        this.playing = false
      }
    },

    async onFlowComplete () {
      await sleep(500)
      this.playing = false
      this.isLoading = false
      this.activeStep = 3
    },

    onFlowFailed () {
      this.openErrorModal = true
      this.clearState()
    },

    clearKeepForTimer() {
      clearTimeout(this.keepForTimer)
      this.keepForTimer = null
    },

    tryAnotherPrompt () {
      this.$trackEvent({
        'event': 'clickOnTryAnotherPrompt'
      })
      this.clearState()
    },

    clearState () {
      this.isLoading = false
      this.activeStep = 1
      this.replicasFlow = []
      this.visibleReplicas = []
      this.websitePreviewUrl = undefined
      this.websitePreviewOptions = null
      this.$router.replace({ 'query': null })
    },

    loaderMsgCounter() {
      setInterval(() => {
        if (this.activeMsgIndex <= preloaderMsgs.length - 1) {
          this.contentMsg = preloaderMsgs[this.activeMsgIndex++]
        } else {
          this.activeMsgIndex = 0
        }
      }, 8000)
    },

    async createNewSpreadView() {
      this.creatingWebsite = true
      this.loaderMsgCounter()
      const url = this.websitePreviewUrl
      const websiteOptions = this.websitePreviewOptions || this.expandedDefaultOptions
      const website = await api.createView({ spreadsheetUrl: url, options: websiteOptions, encodedOptions: createEncodedWriteOptions(websiteOptions) })
      this.$trackEvent({
        'event': 'createWebsite',
        'creationType': 'AI',
        'domain': website.domain
      })
      if (this.licenseTypeToApplyOnCreate !== 'free') {
        await this.updateSpreadViewDeal({
          id: website.id,
          dealType: this.licenseTypeToApplyOnCreate
        })
      }
      this.$router.push({ name: 'manage-sheet-page', params: { id: website.id } })
      this.creatingWebsite = false
    },

    async checkGoogleDrivePermissions() {
      this.$trackEvent({ 'event': 'tryToCreateWithAI' })
      this.isLoading = true
      try {
        const scopeGranted = await ensureGoogleScope('https://www.googleapis.com/auth/drive.file')
        if (!scopeGranted) {
          this.googleDrivePermissionsModal = true
        } else {
          await this.createGoogleSheet()
        }
      } catch (response) {
        console.warn(response)
        this.generationError = response.error.message
        this.openErrorModal = true
      } finally {
        this.isLoading = false
      }
    },

    async createGoogleSheet(getPermissions = false) {
      this.isLoading = true

      const canCreateNewView = await this.checkIfCanCreateNewView()

      if (!canCreateNewView) return

      if (getPermissions) {
        const permissions = await getGoogleDrivePermissions(this.userData.email, 'https://www.googleapis.com/auth/drive.file')
        if (!permissions) {
          this.isLoading = false
          return
        }
      }
      try {
        const { data } = await aiApi.generateSheet(this.generationId)
        this.websitePreviewUrl = data.url
        await this.createNewSpreadView()
      } catch (response) {
        console.warn(response)
        this.generationError = response.error.message
        this.openErrorModal = true
      } finally {
        this.isLoading = false
      }
    }
  },
  async created() {
    if (!this.workspaces) {
      await this.fetchWorkspaces()
    }
    if (this.$route.query && this.$route.query.generationId) {
      this.isLoading = true
      this.generationId = this.$route.query.generationId
      try {
        const { data } = await aiApi.generationStatus(this.$route.query.generationId)
        if (data.status === 'COMPLETED') {
          this.isLoading = false
          this.activeStep = 3
          this.prompt = data.steps.userPrompt
          this.websitePreviewOptions = data.steps.result.options
          this.steps = Object.assign({}, defaultSteps)
          this.steps.rowsWithImages = data.steps.rowsWithImages
        }
      } catch (e) {
        console.warn(e)
      } finally {
        // this.$router.replace({ 'query': null })
        this.isLoading = false
      }
    }
  }
}
</script>
