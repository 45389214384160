<template>
  <span
    class="ui-helper"
    :class="`ui-helper--direction-${direction}`"
  >
    <span
      v-if="label"
      class="ui-helper__label"
      :class="labelClasses ? labelClasses : null"
    >
      {{ label }}
    </span>

    <el-popover
      v-model="popoverVisible"
      v-if="popoverContent || $slots.default || videoData"
      :trigger="triggerAction"
      :placement="popoverPlacement"
      class="ui-helper__popover-wrap"
      :visible-arrow="false"
      :popper-class="popoverClasses"
    >
      <template v-if="popoverContent">
        {{ popoverContent }}
      </template>

      <div
        v-else-if="videoData"
        class="ui-helper__popover-video-wrap"
      >
        <video
          class="ui-helper__popover-video"
          autoplay
          loop
          muted
          :src="videoData.src"
        ></video>
        <div class="ui-helper__popover-video-meta">
          <p v-if="videoData.label" class="ui-helper__popover-video-label">{{ videoData.label }}</p>
          <div v-if="videoData.btns" class="ui-helper__popover-video-btns">
            <UiBtn
              v-for="(btn, index) in videoData.btns"
              :key="index"
              :label="btn.label"
              :to="btn.to"
              :external-resource="btn.externalResource"
              target-blank
              :type="btn.color ? btn.color : 'primary'"
              @click.native="handleClick(btn)"
            />
          </div>
        </div>
      </div>

      <slot v-else />

      <!-- <UiBtn
        icon-only="mi-close"
        size="s"
        type="secondary"
        class="ui-helper__popover-close"
        @click="popoverVisible = false"
      /> -->

      <button
        v-if="showCloseBtn"
        class="ui-helper__popover-close"
        @click="popoverVisible = false"
      >
        <UiIcon icon="mi-close" />
      </button>

      <UiIcon
        slot="reference"
        class="ui-helper__popover-trigger"
        :class="{'ui-helper__popover-trigger--highlighted': popoverVisible}"
        :icon="videoData ? 'mf-play_circle_filled' : triggerIcon"
      />

    </el-popover>

    <UiIcon
      v-else
      class="ui-helper__icon"
      icon="mf-info"
    />

  </span>
</template>
<script>
import UiIcon from '@/components/ui-icon'
import UiBtn from '@/components/ui-btn'
export default {
  name: 'UiHelper',
  components: {
    UiIcon,
    UiBtn
  },
  props: {
    direction: {
      type: String,
      default: 'row',
      validator (direction) {
        return ['row', 'row-reverse'].includes(direction)
      }
    },
    label: {
      type: String,
      default: null
    },
    labelClasses: {
      type: String,
      default: null
    },
    popoverContent: {
      type: String,
      default: null
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    },
    videoData: {
      type: Object,
      default: () => {}
    },
    triggerAction: {
      type: String,
      default: 'click',
      validator (action) {
        return ['click', 'hover'].includes(action)
      }
    },
    triggerIcon: {
      type: String,
      default: 'mf-info'
    },
    popoverPlacement: {
      type: String,
      default: 'bottom-start',
      validator (placement) {
        return [
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'left',
          'left-start',
          'left-end',
          'right',
          'right-start',
          'right-end'
        ].includes(placement)
      }
    },
    popoverFeature: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      popoverVisible: false
    }
  },
  watch: {
    popoverVisible (val) {
      if (val) {
        this.$trackEvent({
          'event': 'helpPopoverEvent',
          'featureName': this.popoverFeature,
          'type': 'open'
        })
      }
    }
  },
  computed: {
    popoverClasses () {
      if (this.popoverContent) {
        return 'ui-helper__popover ui-helper__popover--type-tooltip'
      } else if (this.videoData) {
        return 'ui-helper__popover ui-helper__popover--type-video'
      } else {
        return 'ui-helper__popover ui-helper__popover--type-custom'
      }
    }
  },
  methods: {
    handleClick (btn) {
      const event = btn.event
      this.$trackEvent({
        'event': 'helpPopoverEvent',
        'featureName': this.popoverFeature,
        'type': event.type
      })
    }
  }
}
</script>
