<template>
    <div>
    <UiBtn @click="openPicker" :loading="isLoading" :disabled="isLoading" class="ui-btn--full-width">
        <span>Get link to the SpreadSheet</span>
    </UiBtn>
    <br>
    </div>
</template>
<script>
import { initGoogleSignInScript, loadGapiScript, ensureGoogleScope, getGoogleDrivePermissions } from '@/modules/google-utils'
import { client as apiClient } from '@/services/api'
import { mapState } from 'vuex'
import UiBtn from '@/components/ui-btn/UiBtn.vue'
export default {
  name: 'GoogleFilePicker',
  components: {
    UiBtn
  },
  data: () => {
    return {
      accessToken: null,
      picker: null,
      isLoading: false,
      googleApiKey: process.env.VUE_APP_GOOGLE_PICKER_API_KEY
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ])
  },
  methods: {
    async openPicker() {
      this.isLoading = true
      if (!this.accessToken) {
        await this.initScripts()
      }
      if (this.picker) {
        this.picker.setVisible(true)
        this.isLoading = false
      }
    },
    pickerCallback (data) {
      console.log('pickerCallback', data)
      if (data.action === 'picked') {
        this.$emit('on-link-chosen', data.docs[0].url)
      }
      if (data.action === 'cancel') {
        this.isLoading = false
      }
    },
    createPicker () {
      if (!this.accessToken) {
        return
      }
      this.picker = new window.google.picker.PickerBuilder()
        .addView(window.google.picker.ViewId.SPREADSHEETS)
        .setOAuthToken(this.accessToken)
        .setDeveloperKey(this.googleApiKey)
        .setCallback(this.pickerCallback)
        .build()

      this.picker.setVisible(true)
    },
    deobfuscateToken (obfuscatedToken) {
      const decoded = atob(obfuscatedToken)
      const [part1, _noise, part2] = decoded.split(':')
      return part1 + part2
    },

    async initScripts () {
      try {
        const scopeGranted = await ensureGoogleScope('https://www.googleapis.com/auth/drive.file')
        if (!scopeGranted) {
          const permissions = await getGoogleDrivePermissions(this.userData.email, 'https://www.googleapis.com/auth/drive.file')
          if (!permissions) {
            this.isLoading = false
            return
          }
        }
        const { data } = await apiClient.get(`/google/access-token`)
        console.log(data.access_token)
        await initGoogleSignInScript()
        await loadGapiScript()
        this.accessToken = this.deobfuscateToken(data.access_token)
        console.log(this.accessToken)
        window.gapi.load('picker', this.createPicker)
      } catch (e) {
        console.warn(e)
      }
    }
  }
}

</script>
