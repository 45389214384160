var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "UiBtn",
        {
          staticClass: "ui-btn--full-width",
          attrs: { loading: _vm.isLoading, disabled: _vm.isLoading },
          on: { click: _vm.openPicker },
        },
        [_c("span", [_vm._v("Get link to the SpreadSheet")])]
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }