<template>
  <div class="main">
    <slot></slot> <!-- page content will be rendered instead of <slot></slot> -->
  </div>
</template>
<script>
export default {
  name: 'LayoutHeaderless'
}
</script>
