<template>
  <div>
    <div class="page-create">
      <img src="/img/layout/add-view-dots-bottom.svg" alt="" class="page-create__decor-img is-bottom">
      <img src="/img/layout/add-view-dots-left.svg" alt="" class="page-create__decor-img is-left">
      <div class="page-create__head" ref="pageCreateHead">
        <div class="page-create__meta">
          <h1 class="page-create__meta-title">
            Create new Website
            <button @click="goToAiCreator" class="try-ai-btn page-create__try-ai-btn">
              TRY AI ✨
            </button>
          </h1>
          <div class="page-create__meta-string">
            <p>For a quick start, select one of the templates below and click the <b>Use template</b> button.</p>
            <p>Or if you’ve already prepared your content in Google Sheets, click the <b>From your Google Sheet</b> button and paste the link to your Sheet to build a website from scratch.</p>
            <p>Looking to create something unique but not sure where to start? Click the <b>Try AI</b> button and our AI will generate a website based on your description.</p>
          </div>
        </div>
        <div class="page-create__nav">
          <div class="page-create__nav-item" :class="{ 'router-link-active': createType === 'template' }"
            @click="createType = 'template'">
            From our template
          </div>
          <div class="page-create__nav-item" :class="{ 'router-link-active': createType === 'link' }"
            @click="createType = 'link'">
            From your Google Sheet
          </div>
        </div>
      </div>
      <div v-if="createType === 'link'" class="page-create__form-wrap">
        <SpreadForm :loading="isLoading" :forAddViewPage="true" @submit="submitForm" />
      </div>
      <div v-if="createType === 'template'" class="page-create__cards-wrap">
        <div class="page-create__filters-wrap">
          <div class="filters">
            <div class="filters__section">
              <div class="filters__item">
                <el-input v-model="filterValues.search" placeholder="Search templates" prefix-icon="icon-search-new"
                  class="filters__search" />
              </div>
            </div>
            <div class="filters__section">
              <h5 class="filters__section-title">Category</h5>
              <el-checkbox-group v-model="filterValues.categories" class="filters__section-item is-list-view">
                <el-checkbox v-for="item in filtersOptions.categories" :key="item" :label="item">
                  {{ item }}
                  <!-- <UiTag
                      :label="item.counter"
                      size="xxs"
                      color="secondary"
                    /> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="filters__section">
              <h5 class="filters__section-title">Feature</h5>
              <el-checkbox-group v-model="filterValues.features" class="filters__section-item is-list-view">
                <el-checkbox v-for="item in filtersOptions.features" :key="item" :label="item">
                  {{ item }}
                  <!-- <UiTag
                    :label="item.counter"
                    size="xxs"
                    color="secondary"
                  /> -->
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="filters__footer">
              <UiBtn label="Reset" full-width type="secondary" @click="resetFilters" />
            </div>
          </div>
        </div>
        <SheetSamplesList
          class="page-create__cards"
          :forAddViewPage="true"
          :sources="filteredTemplates"
          @on-link-choice="val => checkGoogleDrivePermissions(val.templateName, val.from)"
        />
      </div>

      <Modal v-model="useTemplateModal" modalName="Create new website from template" :hasCloseButton="false">
        <div class="text--align-center pb-32 mt-20">
          <p>We will create new website based on template data for you.</p>
          <p>Later you can change data and styles of it.</p>
          <UiBtn label="Create" class="ml-auto mr-auto mt-20"
            @click="checkGoogleDrivePermissions(selectedTemplateName)" />
        </div>
      </Modal>
      <Modal v-model="openErrorModal" modalName="Something went wrong :(" :hasCloseButton="true">
        <div class="text--align-center pb-32 mt-20">
          <p>We are working on fixing this issue.</p>
          <p>Please try again later.</p>
          <UiBtn label="OK" class="ml-auto mr-auto mt-20" @click="openErrorModal = false" />
        </div>
      </Modal>
      <Modal
        v-model="googleDrivePermissionsModal"
        :hasCloseButton="false"
        max-width="700px"
        top="auto"
        style="--modalPaddingTop: 0;"
        @input="closeGoogleDrivePermissionsModal"
      >
        <div class="ui-flex ui-flex--items-start gap-20 p-28">
          <img
            src="/img/lock-2.svg"
            alt="lock icon"
            class="ui-flex--none"
          >
          <div>
            <h4 class="heading--size-s text--weight-700">Allow SpreadSimple to create and read the created Google Sheets
            </h4>
            <p class="mt-12 text--weight-500">The app will not be able to access or read any other files stored on your
              Google Drive. Your other data will remain safe.</p>
          </div>
          <div>
            <UiBtn label="Allow..." uppercase full-width @click="createFromTemplate(selectedTemplateName, true)" />
            <UiBtn label="Dismiss" uppercase full-width class="mt-12" type="secondary-text-blue"
              @click="closeGoogleDrivePermissionsModal" />
          </div>
        </div>
      </Modal>
    </div>
    <Preloader :loading="isLoading" :content="contentMsg" showProgress />
  </div>
</template>
<script>
import UiBtn from '@/components/ui-btn/UiBtn.vue'
// import UiTag from '@/components/ui-tag/UiTag.vue'
import api from '@/services/api/'
import defaultFieldsSchema from '@/components/form-builder/defaultFieldsSchema.js'
import { defaultOptions } from 'spreadview-options'
import SpreadForm from '@/components/spread-form'
import SheetSamplesList from '@/components/sheet-samples-list'
import Modal from '@/components/modal'
import { samples } from '@/modules/sample-views'
import { mapState, mapGetters, mapActions } from 'vuex'
import sampleOptions from '@/modules/sample-options'
import { ensureGoogleScope, getGoogleDrivePermissions } from '@/modules/google-utils'
import { createEncodedWriteOptions } from 'google-sheets-data'
import {
  setOnboardingWizardStepAsCompleted
} from '@/modules/wizards/onboarding'

const preloaderMsgs = [
  '💡 A template doesn’t limit your website behavior — all features and add-ons are available for all websites',
  '💡 Optimize your images: smaller files take less time to load',
  '💡 Don’t forget about meta: add meta tags to all your pages for better SEO results',
  '💡 Submit your sitemap to Google Search Console to get your website indexed faster',
  '💡 Highlight certain items in the catalog using our .class trick — visit the help desk for the instructions',
  '💡 Custom scripts and custom CSS are welcome — you can inject them in the Settings tab',
  '💡 Need category pages? Create them using the URL aliases feature — visit the help desk for more details'
]

export default {
  preloaderMsgs,
  name: 'AddViewPage',
  metaInfo: {
    title: 'Add new Spreadview'
  },
  components: {
    SpreadForm,
    SheetSamplesList,
    Modal,
    // UiTag,
    UiBtn
  },
  props: {
    useTemplate: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      createType: 'template',
      contentMsg: preloaderMsgs[0],
      activeMsgIndex: 1,
      isLoading: false,
      selectedSample: '',
      useTemplateModal: false,
      templates: samples,
      sampleOptions: sampleOptions,
      selectedTemplateName: undefined,
      selectedTemplateCopyLink: undefined,
      ownerSheetUrl: undefined,
      openErrorModal: false,
      googleDrivePermissionsModal: false,
      filterValues: {
        search: '',
        categories: [],
        features: []
      }
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    ...mapGetters('app', [
      'licenseTypeToApplyOnCreate',
      'onboardingWizardUncompletedSteps'
    ]),
    expandedDefaultOptions() {
      const copyOfDefaults = JSON.parse(JSON.stringify(defaultOptions))
      copyOfDefaults.checkout.fields = defaultFieldsSchema.schema
      return copyOfDefaults
    },
    filtersOptions() {
      return this.templates.reduce((acc, template) => {
        const { categories, features } = template
        categories?.forEach(category => {
          if (!acc.categories.find(c => c === category)) {
            acc.categories.push(category)
          }
        })
        features?.forEach(feature => {
          if (!acc.features.find(f => f === feature)) {
            acc.features.push(feature)
          }
        })
        return acc
      }, { categories: [], features: [] })
    },
    filteredTemplates() {
      const { search, categories, features } = this.filterValues
      return this.templates.filter(template => {
        const { categories: templateCategories, features: templateFeatures } = template
        return (
          (!search || template.templateName.toLowerCase().includes(search.toLowerCase()) ||
            templateCategories?.some(category => category.toLowerCase().includes(search.toLowerCase())) ||
            templateFeatures?.some(feature => feature.toLowerCase().includes(search.toLowerCase()))) &&
          (categories.length === 0 || categories.some(category => templateCategories?.includes(category))) &&
          (features.length === 0 || features.some(feature => templateFeatures?.includes(feature)))
        )
      })
    }
  },
  watch: {
    createType(val) {
      if (val === 'link') {
        this.$trackEvent({
          'event': 'openCreateFromGSTab'
        })
      }
    },
    filterValues: {
      handler () {
        const element = this.$refs.pageCreateHead
        element.scrollIntoView({ behavior: 'smooth' })
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('app', [
      'fetchWorkspaces',
      'updateSpreadViewDeal',
      'updateOnboardingWizardState'
    ]),

    goToAiCreator () {
      this.$trackEvent({
        'event': 'clickOnTryAi',
        'source': 'add-view-page'
      })
      this.$router.push('/ai-creator')
    },
    resetFilters() {
      this.filterValues.search = ''
      this.filterValues.categories = []
      this.filterValues.features = []
    },
    expandWithCheckoutFields(sampleOptions, templateVersion) {
      const checkoutNotExists = templateVersion === 'v2'
        ? (!sampleOptions.data.options.checkout || sampleOptions.data.options.checkout.fields === 0)
        : (!sampleOptions.options.checkout || sampleOptions.options.checkout.fields === 0)

      if (checkoutNotExists) {
        const defaultCheckout = defaultOptions.checkout
        defaultCheckout.fields = defaultFieldsSchema.schema
        const copyOfSampleOptions = JSON.parse(JSON.stringify(sampleOptions))
        if (templateVersion === 'v2') {
          copyOfSampleOptions.data.options['checkout'] = defaultCheckout
        } else {
          copyOfSampleOptions.options['checkout'] = defaultCheckout
        }
        return copyOfSampleOptions
      }
      return sampleOptions
    },
    submitForm(url) {
      this.createNewSpreadView(url)
    },

    loaderMsgCounter() {
      setInterval(() => {
        if (this.activeMsgIndex <= preloaderMsgs.length - 1) {
          this.contentMsg = preloaderMsgs[this.activeMsgIndex++]
        } else {
          this.activeMsgIndex = 0
        }
      }, 8000)
    },

    async createNewSpreadView(url, options = null) {
      this.isLoading = true
      this.loaderMsgCounter()
      const websiteOptions = options || this.expandedDefaultOptions
      const website = await api.createView({ spreadsheetUrl: url, options: websiteOptions, encodedOptions: createEncodedWriteOptions(websiteOptions) })
      this.$trackEvent({
        'event': 'createWebsite',
        'creationType': 'spreadsheet',
        'domain': website.domain
      })
      if (this.licenseTypeToApplyOnCreate !== 'free') {
        await this.updateSpreadViewDeal({
          id: website.id,
          dealType: this.licenseTypeToApplyOnCreate
        })
      }
      if (
        this.onboardingWizardUncompletedSteps.some(s => s.name === 'createWebsite')
      ) {
        try {
          const wizards = await setOnboardingWizardStepAsCompleted('createWebsite')
          this.updateOnboardingWizardState(wizards)
        } catch (err) {
          console.warn(err)
        }
      }
      this.$router.push({ name: 'manage-sheet-page', params: { id: website.id } })
      this.isLoading = false
    },

    showCreateTemplateModal(templateName) {
      this.selectedTemplateName = templateName
      this.useTemplateModal = true
    },

    async checkGoogleDrivePermissions(templateName, from) {
      this.$trackEvent({ 'event': 'tryToCreateFromTemplate', from: from || 'add-page', 'template': templateName })
      this.selectedTemplateName = templateName
      this.isLoading = true
      try {
        const scopeGranted = await ensureGoogleScope('https://www.googleapis.com/auth/drive.file')
        if (!scopeGranted) {
          this.googleDrivePermissionsModal = true
        } else {
          await this.createFromTemplate(templateName)
        }
      } catch (err) {
        console.warn(err)
      } finally {
        this.isLoading = false
      }
    },

    async createFromTemplate(templateName, getPermissions = false) {
      this.useTemplateModal = false
      this.googleDrivePermissionsModal = false
      this.isLoading = true
      this.loaderMsgCounter()

      let website = null
      const currentTemplateVersion = this.templates.find(t => t.templateName === templateName).version
      const currentSampleOptions = this.expandWithCheckoutFields(this.sampleOptions[templateName], currentTemplateVersion)

      if (getPermissions) {
        const permissions = await getGoogleDrivePermissions(this.userData.email, 'https://www.googleapis.com/auth/drive.file')
        if (!permissions) {
          this.isLoading = false
          return
        }
      }

      const { spreadsheetUrl } = await api.initTemplate(templateName)
      if (currentTemplateVersion === 'v2') {
        currentSampleOptions.data.options.emailOptions.notificationsEmailTo = this.userData.email
        currentSampleOptions.data.options.emailOptions.receiptReplyToEmail = this.userData.email
        website = await api.createView({
          spreadsheetUrl,
          options: currentSampleOptions.data.options,
          integrations: currentSampleOptions.data.integrations,
          encodedOptions: createEncodedWriteOptions(currentSampleOptions.data.options)
        })
      } else {
        currentSampleOptions.options.emailOptions.notificationsEmailTo = this.userData.email
        website = await api.createView({
          spreadsheetUrl,
          options: currentSampleOptions.options,
          encodedOptions: createEncodedWriteOptions(currentSampleOptions.options)
        })
      }
      this.$trackEvent({
        'event': 'createWebsite',
        'creationType': `template:${templateName}`,
        'domain': website.domain
      })
      if (this.licenseTypeToApplyOnCreate !== 'free') {
        await this.updateSpreadViewDeal({
          id: website.id,
          dealType: this.licenseTypeToApplyOnCreate
        })
      }
      if (
        this.onboardingWizardUncompletedSteps.some(s => s.name === 'createWebsite')
      ) {
        try {
          const wizards = await setOnboardingWizardStepAsCompleted('createWebsite')
          this.updateOnboardingWizardState(wizards)
        } catch (err) {
          console.warn(err)
        }
      }
      localStorage.setItem('initTemplateName', templateName)
      this.$router.push({ name: 'manage-sheet-page', params: { id: website.id, isWebsiteInit: true } })
      this.isLoading = false
    },

    closeGoogleDrivePermissionsModal() {
      this.googleDrivePermissionsModal = false
      this.selectedTemplateName = undefined
    }

  },
  async created() {
    if (!this.workspaces) {
      await this.fetchWorkspaces()
    }
    if (this.$route.query.useTemplate !== undefined &&
      this.templates.find(t => t.templateName === this.$route.query.useTemplate)) {
      this.showCreateTemplateModal(this.$route.query.useTemplate)
    }
  },
  mounted() {
    localStorage.removeItem('ssFirstLogin')
  }
}
</script>
