var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spreadviews" }, [
    _c(
      "div",
      {
        staticClass: "spreadviews__container spreadviews__short-list-container",
      },
      [
        _vm.activeStep === "websites-list"
          ? _c(
              "div",
              { staticClass: "spreadviews__right" },
              [
                _vm._m(0),
                _vm.isLoading
                  ? _c("Preloader", { attrs: { loading: _vm.isLoading } })
                  : _vm._e(),
                !_vm.isLoading && _vm.viewsList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "spreadviews__short-list" },
                      _vm._l(_vm.viewsList, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass:
                              "spreadview spreadviews__short-list-item",
                            on: {
                              click: function ($event) {
                                return _vm.onWebsiteClick(item)
                              },
                            },
                          },
                          [
                            _c("div", [
                              _c("img", {
                                staticClass: "spreadviews__short-list-item-img",
                                attrs: {
                                  src: item.coverImage || "/img/noPreview.png",
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "spreadviews__short-list-item-content",
                              },
                              [
                                _c("h3", { staticClass: "spreadview__title" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.options.spreadView.name) +
                                      "\n            "
                                  ),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "spreadview__link-title" },
                                  [_vm._v(_vm._s(_vm.websiteUrl(item)))]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "spreadviews__short-list-item-action",
                              },
                              [
                                _c("UiBtn", {
                                  staticClass: "manage-header__back-btn",
                                  attrs: {
                                    "icon-only": "mi-keyboard_backspace",
                                    size: "m",
                                    type: "ghost-secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onWebsiteClick(item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                !_vm.isLoading && _vm.viewsList.length === 0
                  ? _c(
                      "div",
                      { staticClass: "spreadviews__short-list" },
                      [
                        _c(
                          "div",
                          { staticClass: "spreadviews__short-list-no-sites" },
                          [_vm._v("No websites found.")]
                        ),
                        _c("UiBtn", {
                          attrs: {
                            label: "Create Your First Website",
                            to: "/add",
                            targetBlank: true,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.activeStep === "show-shortcode"
          ? _c("div", { staticClass: "spreadviews__right" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "spreadviews__short-list" },
                [
                  _c(
                    "div",
                    { staticClass: "spreadview spreadviews__short-list-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "spreadviews__short-list-item-content" },
                        [
                          _c("h3", { staticClass: "spreadview__shortcode" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.shortCode) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c("UiBtn", {
                    attrs: {
                      label: _vm.requiredAction === "copy" ? "Copy" : "Insert",
                    },
                    on: { click: _vm.sendShortCode },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spreadviews__list-head" }, [
      _c("h1", { staticClass: "spreadviews__title" }, [
        _vm._v("Select a website to embed"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "spreadviews__list-head" }, [
      _c("h1", { staticClass: "spreadviews__title" }, [
        _vm._v("Your short code:"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }