<template>
  <div v-if="workspaces">
    <template v-if="simpified">
      <el-popover
        v-model="showWorkspacesDropdown"
        placement="bottom-start"
        width="400"
        trigger="click"
        :visible-arrow="false"
        class="workspaces-popover"
        popper-class="workspaces-popover__menu"
      >
        <div v-for="(ws, i) in workspacesInOrder" :key="i"
          class="workspaces-popover__menu-item"
          :class="{'disabled': wsUserInactive(ws)}"
          @click="updateCurrentWorkspaceId(ws.id)"
        >
          <div class="workspaces-popover__user">
            <div class="workspaces-popover__user-meta">
              <h4 class="workspaces-popover__user-title"> {{ws.title}} <span v-if="ws.main">(YOUR)</span> </h4>
              <p class="workspaces-popover__user-subtitle"> {{wsUserInactive(ws) ? 'Access denied, please contact workspace owner' : '' }} </p>
            </div>
          </div>
          <i v-if="currentWorkspaceId === ws.id" class="workspaces-popover__menu-item-check icon-check2"></i>
        </div>

        <div slot="reference" class="workspaces-popover__trigger">
          <div v-if="currentWorkspace" class="workspaces-popover__user">
            <div class="workspaces-popover__user-meta">
              <h4 class="workspaces-popover__user-title"> {{currentWorkspace.title}} <span v-if="currentWorkspace.main">(YOUR)</span> </h4>

            </div>
          </div>
          <i :class="{'is-flipped': showWorkspacesDropdown}" class="workspaces-popover__trigger-chevron icon-arrow-down"></i>
        </div>
      </el-popover>
    </template>
    <template v-else>
      <el-popover
        v-model="showWorkspacesDropdown"
        placement="bottom-start"
        width="400"
        trigger="click"
        :visible-arrow="false"
        class="workspaces-popover"
        popper-class="workspaces-popover__menu"
      >
        <div v-for="(ws, i) in workspacesInOrder" :key="i"
          class="workspaces-popover__menu-item"
          :class="{'disabled': wsUserInactive(ws)}"
          @click="updateCurrentWorkspaceId(ws.id)"
        >
          <div class="workspaces-popover__user">
            <div class="workspaces-popover__user-meta">
              <h4 class="workspaces-popover__user-title"> {{ws.title}} <span v-if="ws.main">(YOUR)</span> </h4>
              <p class="workspaces-popover__user-subtitle"> {{ws.users.length}} member{{ws.users.length > 1 ? 's' : ''}} </p>
              <p class="workspaces-popover__user-subtitle"> {{wsUserInactive(ws) ? 'Access denied, please contact workspace owner' : '' }} </p>
            </div>
          </div>
          <i v-if="currentWorkspaceId === ws.id" class="workspaces-popover__menu-item-check icon-check2"></i>
        </div>

        <div slot="reference" class="workspaces-popover__trigger">
          <div v-if="currentWorkspace" class="workspaces-popover__user">
            <div class="workspaces-popover__user-meta">
              <h4 class="workspaces-popover__user-title"> {{currentWorkspace.title}} <span v-if="currentWorkspace.main">(YOUR)</span> </h4>
              <p class="workspaces-popover__user-subtitle"> {{currentWorkspace.users.length}} member{{currentWorkspace.users.length > 1 ? 's' : ''}} </p>

            </div>
          </div>
          <i :class="{'is-flipped': showWorkspacesDropdown}" class="workspaces-popover__trigger-chevron icon-arrow-down"></i>
        </div>
      </el-popover>
      <UiBtn
        v-if="currentWorkspaceId === mainWorkspaceId"
        type="secondary"
        full-width
        :to="{ name: 'account-workspaces' }">
        Settings
      </UiBtn>
    </template>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import UiBtn from '@/components/ui-btn'
export default {
  name: 'WorkspaceDropdown',
  components: {
    UiBtn
  },
  props: {
    simpified: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showWorkspacesDropdown: false
    }
  },
  computed: {
    ...mapState('app', [
      'workspaces',
      'currentWorkspaceId',
      'userData'
    ]),
    ...mapGetters('app', [
      'currentWorkspace'
    ]),
    workspacesInOrder () {
      return this.workspaces.reduce((acc, ws) => {
        if (ws.main) {
          acc.unshift(ws)
        } else {
          acc.push(ws)
        }
        return acc
      }, [])
    },
    mainWorkspaceId () {
      return this.workspaces ? this.workspaces.find(ws => ws.main).id : undefined
    }
  },
  methods: {
    ...mapActions('app', [
      'setWorkspaceId',
      'fetchWorkspaces'
    ]),
    updateCurrentWorkspaceId (id) {
      this.showWorkspacesDropdown = false
      this.setWorkspaceId(id)
      this.$emit('on-ws-change')
    },
    wsUserInactive (ws) {
      const user = ws.users.find(u => u.user === this.userData.id)
      if (!user) return false
      const status = user.status
      return (status === 'INACTIVE')
    }
  },
  async created () {
    if (!this.workspaces) {
      await this.fetchWorkspaces()
    }
  }
}
</script>
