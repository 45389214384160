<template>
  <header v-scroll-class:scrolled=70 class="header for-manage">
      <!-- @ON-MAINTENANCE: delete this afrer maintenance end -->
      <!-- <div class="header-trialing-bar is-maintenance">
        ⚠️ Due to the technical issues, you may experience problems with creating websites from templates and accessing the Sheets that were created from the templates. This doesn’t affect your websites and your data in the Sheet. We are working on fixing this issue. Please check again later.
      </div> -->
      <!-- @ON-MAINTENANCE: end -->
    <div class="pl-20 pr-20 pt-12 header-msg-box" v-if="showFreeLimitsBanner">
      <UiMsgBox color="warning">
        <div class="flex align-items-center justify-content-between">
          <span>
            <span class="text--weight-600">Important Update:</span> Changes to custom row limits
          </span>
          <UiBtn
            size="s"
            @click="showFreeLimitsModal = true"
            type="warning"
          >Learn More</UiBtn>
        </div>
      </UiMsgBox>
    </div>
    <!-- <div class="header-trialing-bar">
    </div> -->

    <TrialNotificationBar :spreadViewId="null"/>

    <div class="header__container">
      <a href="/" class="header__logo">
        <img src="/img/logo-main.svg">
      </a>
      <div class="header__right-side">
        <ProfileDropdown :userData="userData || {}" />
      </div>
    </div>

    <Modal
      v-model="showFreeLimitsModal"
      modalName="Changes to custom row limits"
      :hasCloseButton="true"
      @close="showFreeLimitsModal = false"
    >
      <div class="pl-40 pr-40 pt-20 pb-40 text--size-m">
        <p>
          Starting on <span class="text--weight-600">February 20th, 2025</span>, all websites created on the free plan before June 27th, 2024,
          will be limited to 50 Google Sheet rows per website, in line with those created after that date.
          If you need more Google sheet rows, please consider upgrading your plan.
          You can check out our pricing and available features <a href="https://spreadsimple.com/pricing/" class="is-link">here</a>.
        </p>
        <p class="mt-16">
          We understand that changes can be challenging,
          but please know that these adjustments are necessary for the future growth of our platform.
          We`re committed to continuously improving our services and providing you with more and
          more features. Thank you for your understanding.
        </p>
      </div>
    </Modal>
  </header>
</template>
<script>
import ProfileDropdown from '@/components/profile-dropdown'
import TrialNotificationBar from '@/components/trial-notification-bar'
import Modal from '@/components/modal'
import UiBtn from '@/components/ui-btn'
import UiMsgBox from '@/components/ui-msg-box'
import UiTag from '@/components/ui-tag'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'LayoutHeader',
  components: {
    ProfileDropdown,
    TrialNotificationBar,
    Modal,
    UiBtn,
    UiMsgBox,
    UiTag
  },
  data () {
    return {
      showFreeLimitsModal: false
    }
  },
  computed: {
    ...mapState('app', [
      'userData'
    ]),
    ...mapGetters('app', [
      'showFreeLimitsBanner'
    ])
  }
}
</script>
