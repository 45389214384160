import api from '@/services/api/'
import mixpanel from 'mixpanel-browser'
import { initOpenReplay, openReplayIdentifyUser } from 'open-replay'
export function initMixpanel() {
  if (!process.env.VUE_APP_MIXPANEL_TOKEN) return
  mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, { debug: process.env.NODE_ENV !== 'production' })
}

let orTracker
export async function initOr() {
  orTracker = await initOpenReplay(process.env.NODE_ENV, process.env.VUE_APP_OR_PROJECT_KEY, process.env.VUE_APP_MAIN_APP_BASE_URL)
}

initMixpanel()
initOr()

export function mixpanelTrack(...args) {
  if (!process.env.VUE_APP_MIXPANEL_TOKEN) return
  mixpanel.track(...args)
}
export function orTrack(...args) {
  if (!orTracker) return
  orTracker.event(...args)
}

export function setUserCookieData(me) {
  if (!['spreadsimple.com', 'localhost'].some(el => location.host.includes(el))) return
  document.cookie = `__ss_user_email=${me.email}; path=/; domain=.spreadsimple.com; max-age=604800`
}

export function deleteUserCookieData() {
  if (!['spreadsimple.com', 'localhost'].some(el => location.host.includes(el))) return
  document.cookie = `__ss_user_email=; path=/; domain=.spreadsimple.com; max-age=-1`
}

function identifyCrispChat(me) {
  const $crisp = window.$crisp
  try {
    $crisp.push(['set', 'user:email', me.email])
    $crisp.push(['set', 'user:nickname', `${me.firstName} ${me.lastName}`])
    $crisp.push(['set', 'session:data', [[
      ['id', me.id],
      ['registered', me.createdAt],
      ['LTD licenses', me.appsumoOptions.spreadViewsLimit],
      ['plan', me.deal],
      ['gaClientId', me.gaClientId],
      ['payedSpreadViews', me.dealOptions.payedSpreadViews],
      ['payedSpreadViewsLimit', me.dealOptions.payedSpreadViewsLimit],
      ['promoSpreadViewsLimit', me.dealOptions.promoSpreadViewsLimit],
      ['nextBillDate', me.dealOptions.nextBillDate]
    ]]])
    api.getViewsList().then(views => {
      const websites = views.map(w => w.customDomain || w.domain).join(', ')
      $crisp.push(['set', 'session:data', [[
        ['websites', websites]
      ]]])
    })
  } catch (e) {
    console.warn('Couldn\'t identify user')
  }
}

function setFeatureToggles(me) {
  try {
    const features = me.features || []
    features.forEach(feature => {
      const [ftKey, ftValue] = feature.split('=')
      localStorage.setItem(ftKey, ftValue)
    })
  } catch (e) {
    console.warn('Failed to set feature toggles')
  }
}

export function storeUserId(me) {
  if (mixpanel) {
    mixpanel.identify(me.id)
    mixpanel.people.set({ '$name': `${me.firstName} ${me.lastName}`, '$email': me.email })
  }
  localStorage.setItem('ssUserId', me.id)
  setFeatureToggles(me)
  setUserCookieData(me)
  openReplayIdentifyUser(me.email, process.env.NODE_ENV)
  identifyCrispChat(me)
}

export function getUserId() {
  const userId = localStorage.getItem('ssUserId')
  const refreshToken = localStorage.getItem('refreshToken')

  if (refreshToken && userId) return userId
}

export function sendUserId() {
  if (!window.dataLayer) window.dataLayer = []

  window.dataLayer.push({
    userId: getUserId()
  })
}

export function getGaClientId() {
  if (!window.ga) return
  try {
    return window.ga.getAll()[0].get('clientId')
  } catch (e) {
    console.warn(e)
  }
}

export function trackEvent(data = {}) {
  try {
    const { event, ...props } = data
    mixpanelTrack(event, props)
    orTrack(event, props)

    if (!window.dataLayer) window.dataLayer = []
    window.dataLayer.push({
      ...data,
      userId: getUserId()
    })

    if (!window.$crisp) window.$crisp = []

    window.$crisp.push(['set', 'session:event', [[
      [event, props]]
    ]])
  } catch (e) {
    console.debug(`Failed to log analytics event`)
  }
}
